<template>
  <!-- #e4e4e4 -->
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="12" xl="8">
          <h1>Referenzen</h1>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fluid
      v-for="(item, i) in value"
      :key="i"
      :style="backgroundByIndex(i)"
    >
      <!-- <v-lazy
        :options="{
          threshold: 0.25,
          // viel Prozent sichtbar sein müssen, bevor es gerendert wird
        }"
        min-height="200"
        transition="fade-transition"
      > -->
        <v-container>
          <v-row justify="center">
            <v-col cols="12" md="12" xl="8">
              <h2>{{ item.referenceName }}</h2>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" xl="4" order="1" :order-md="textOrder(i)">
              <v-card
                :style="
                  $vuetify.breakpoint.mobile ? 'margin-bottom: -25px' : ''
                "
              >
                <v-card-text>
                  {{ item.referenceDescription }}
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    text
                    color="#1d4d9c"
                    :to="item.referencePageRouterName"
                  >
                    Mehr Details
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
              xl="4"
              order="2"
              :order-md="pictureOrder(i)"
            >
              <v-card
                :style="$vuetify.breakpoint.mobile ? '' : rotationByIndex(i)"
              >
                  <v-carousel
                    style="
                      box-shadow: 1.04375em 1.04375em 0.9625em 0
                        rgba(0, 0, 0, 0.4);
                    "
                    height="400px"
                    :show-arrows="item.images[1] === undefined ? false : true"
                    :cycle="item.cycle === undefined ? false : item.cycle"
                    :interval="
                      item.interval === undefined ? false : item.interval
                    "
                    :hide-delimiters="
                      item.images[1] === undefined ? true : false
                    "
                  >
                    <v-carousel-item
                      @click="
                        imageFullscreenView = {
                          active: true,
                          images: item.images,
                          index: i,
                        }
                      "
                      v-for="(image, i) in item.images"
                      :key="i"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                    >
                      <v-img
                        :src="image.imageSrc"
                        height="100%"
                        width="100%"
                        :lazy-src="getImageLazyPath(image.imageSrc)"
                        ><template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-carousel-item>
                  </v-carousel>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      <!-- </v-lazy> -->
    </v-container>
    <ImageFullscreenView
      v-if="imageFullscreenView.active"
      v-model="imageFullscreenView"
      @fullscreenClose="imageFullscreenView.active = false"
    />
  </div>
</template>
<script>
import ImageFullscreenView from '@/components/ImageFullscreenView.vue';

export default {
  name: 'TextPictureSwitch',
  props: ['value'],
  components: {
    ImageFullscreenView,
  },
  methods: {
    getImageLazyPath: (imgSrc) => {
      const path = imgSrc.substring(0, imgSrc.lastIndexOf('/'));
      const imageFileFormat = imgSrc.substring(imgSrc.lastIndexOf('/') + 1);
      // console.log(path + '##########' + imageFileFormat);
      const imageLazyPath = path + '_lazy/' + imageFileFormat;
      return imageLazyPath;
    },
    backgroundByIndex: (index) => {
      const backgroundColor = index % 2 === 0 ? '' : 'background-color:#e4e4e4';
      return backgroundColor;
    },
    rotationByIndex: (index) => {
      /* eslint-disable operator-linebreak */
      const rotation =
        index % 2 === 0
          ? 'transform: rotate(-3deg)'
          : 'transform: rotate(3deg)';
      return rotation;
      /* eslint-disable operator-linebreak */
    },
    // change col order every second row (switch image and text)
    pictureOrder: (index) => {
      const colOrder = index % 2 === 1 ? '1' : '2';
      return colOrder;
    },
    textOrder: (index) => {
      const colOrder = index % 2 === 1 ? '2' : '1';
      return colOrder;
    },
  },
  data: () => ({
    imageFullscreenView: {
      active: false,
      images: Object,
    },
    //
  }),
};
</script>
