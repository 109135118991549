<template>
  <v-dialog
    v-model="fullscreen"
    persistent
    fullscreen
    transition="scroll-x-reverse-transition"
  >
    <v-container
      fluid
      style="background-color: rgba(46, 49, 49, 0.95); height: 100%"
    >
      <v-container style="height: 100%" max-width="800">
        <!-- reverse-transition="fade-transition"
          transition="fade-transition"
          :src="image.imageSrc" -->
        <v-img
          v-touch="{
            left: () => nextImage(),
            right: () => prevImage(),
          }"
          contain
          height="100%"
          :src="images[imageIndex].imageSrc"
        >
        </v-img>
      </v-container>
    </v-container>
    <v-btn
      fab
      dark
      fixed
      bottom
      left
      color="primary"
      v-if="this.imageIndex > 0"
      @click="prevImage()"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn
      fab
      dark
      fixed
      bottom
      right
      color="primary"
      v-if="this.imageIndex < this.imageCount"
      @click="nextImage()"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
    <v-btn fab dark fixed top right color="primary" @click="fullscreen = false">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-dialog>
</template>

<script>
export default {
  name: 'ImageFullscreenView',
  props: ['value'],
  computed: {},
  data: () => ({
    fullscreen: false,
    images: Object,
    imageIndex: 0,
    imageCount: 0,
    //
  }),
  methods: {
    fullscreenClose() {
      this.$emit('fullscreenClose');
    },
    getImageSrc() {
      /* eslint-disable prefer-destructuring */
      if (this.images[this.imageIndex] !== undefined) {
        const imageSrc = this.images[this.imageIndex].imageSrc;
        return imageSrc;
      }
      return '';
      /* eslint-enable prefer-destructuring */
    },
    nextImage() {
      if (this.imageIndex < this.imageCount) {
        this.imageIndex += 1;
      }
    },
    prevImage() {
      if (this.imageIndex > 0) {
        this.imageIndex -= 1;
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        /* eslint-disable radix */

        this.fullscreen = newVal.active;
        this.images = newVal.images;
        this.imageIndex = parseInt(newVal.index);
        this.imageCount = parseInt(Object.entries(newVal.images).length) - 1;
      },
    },
  },
};
/* eslint-disable max-len */

/* Json Parameter component <ImageGallery v-model="<value>" />
    {
        active: boolean,            // activiert die fullscreen ansicht
        images: item.images,            // liste der images im unten stehenden Format
        index: i,               //index des bildes welches zuerst aus einer Liste mit Bildern angezeigt werden soll (default 0)
    }
*/
/* eslint-enable max-len */
</script>
<style>
.v-carousel__item {
  margin: auto;
}
</style>
