<template>
  <div class="references">
    <TextPictureSwitch v-model="referencesData"/>
  </div>
</template>

<script>
// @ is an alias to /src
import TextPictureSwitch from '@/components/TextPictureSwitch.vue';

export default {
  name: 'References',
  components: {
    TextPictureSwitch,
  },
  data: () => ({
    referencesData: {
      0: {
        referenceName: 'Eintracht Kraftraum',
        referenceDescription:
        'Aus einem ungenutzen Raum im 1. OG der Tennishalle wurde auf zwei Etagen ein stilvoller und moderner Kraftraum für die Mitglieder des Eintrachter Tennisvereins. Der Zugang zu dem Kraftraum wurde durch den Bau einer Außenbetontreppe ermöglicht. Neben der Demontage und Abbrucharbeiten waren hauptsächlich Dämm- und Isolierarbeiten, Trockenbau einschließlich Brandschutz und Malerarbeiten notwendig. Mit dem Fließestrich wurde ein begehbarer Fußboden gemacht, der für den speziellen Sportler-Gummibelag einen optimalen Untergrund liefert.',
        referencePageRouterName: 'references/eintracht-kraftraum',
        interval: 6000,
        images: {
          0: {
            imageName: 'test',
            imageDescription: 'test',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/eintracht-kraftraum/30.jpg',
          },
          1: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/eintracht-kraftraum/1.jpg',
          },
        },
      },
      // next
      1: {
        referenceName: 'Eintracht Treppenhaus',
        referenceDescription:
          'Renovierung des Treppenhauses in der Eintrachter Turnabteilung: Nach Abbruch und Demontage folgten die Verputzarbeiten. Einige Kabeln, die vorher sichtbar waren, verschwanden unter dem neuen Putz. Anschließend wurden alle Decken und Wände gestrichen und farblich abgesetzt, sowie Lackierarbeiten am Treppengeländer ausgeführt. Zum Schluß kamen die Boden- bzw. Treppenbelagsarbeiten, inkl. Wangen und Blenden.',
        referencePageRouterName: 'references/eintracht-treppenhaus',
        interval: 6000,
        images: {
          0: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/eintracht-treppenhaus/10.jpg',
          },
          1: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/eintracht-treppenhaus/1.jpg',
          },
        },
      },
      // next
      2: {
        referenceName: 'Eintracht Fassade',
        referenceDescription:
          'Sanierung der Fassade in der Turnabteilung: Alle Risse am Mauerwerk des alten Eintrachter Turngebäudes wurden mit speziellem Spiralankersystem sorgfältich saniert, einige Kabel und Leitungen verschwanden unter dem Putz. Im Zuge der Sanierung wurde ein alter Kellerabgang in einen ordnungsgemäßen und gebrauchsfähigen Zustand versetzt, sowie eine hystorische Backsteinsäule sandgestrahlt, behandelt und versiegelt. Die komplette Fassadenfläche wurde mit Panzergewebe und speziellem Kleber armiert. Nach dem Oberputz kam der neue Anstrich mit den typischen "Eintracht-Farben", der das Gebäude in einem neuen Licht erscheinen ließ.',
        referencePageRouterName: 'references/eintracht-fassade',
        interval: 6000,
        images: {
          0: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/eintracht-fassade/16.jpg',
          },
          1: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/eintracht-fassade/5.jpg',
          },
        },
      },
      // next
      3: {
        referenceName: 'Neue Mauer und neuer Gehweg im Eintrachter Turnverein',
        referenceDescription:
          'Die alte marode Mauer, einschließlich das Gartenhaus des Nachbars, wurde abgerissen und die alten Waschbetonplatten am Gehweg entfernt. Nach mühsamer Erd- und Vorbereitungsarbeiten war es nun möglich, ein stabiles Betonfundament auszuschalen und zu gießen. Darauf wurde mit Bimssteinen gemauert und es entstand eine Mauer mit insgesamt 40 m Länge und 160-210 cm Höhe. Die Mauer wurde oberflächig und farblich an die bereits sanierte Fassade angepasst. Ein Teil der alten Backsteinmauer konnten wir retten, verschönern und als Denkmal zum Ausdruck bringen. Der Geh- und Fahradweg wurde mit neuen Pflastersteinen verlegt.',
        referencePageRouterName: 'references/eintracht-mauer',
        interval: 6000,
        images: {
          0: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/eintracht-mauer/17.jpg',
          },
          1: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/eintracht-mauer/1.jpg',
          },
        },
      },
      // next
      4: {
        referenceName: 'Badsanierung in Taunusstein',
        referenceDescription:
          'Hier wurde ein Bad kernsaniert. Alte Sanitärobjekte wurden demontiert und die Wandfliesen abgeschlagen. Die Dusche wurde umgebaut. Alle Wände wurden raumhoch gefliest und die Bodenfliesen verlegt. Eine neue weiße Tür, sowie Einbaustrahler in der Decke, die glatt gespachtelt und mit geeigneter Farbe gestrichen wurde, runden das Gesamtbild ab.',
        referencePageRouterName: 'references/bad-sanierung-taunusstein',
        interval: 6000,
        images: {
          0: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/bad-sanierung-taunusstein/10.jpg',
          },
          1: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/bad-sanierung-taunusstein/1.jpg',
          },
        },
      },
      // next
      5: {
        referenceName: 'Brandschaden in Bingen',
        referenceDescription:
          'Wiederherstellungsarbeiten nach Brandschaden: Nach dem Brandschaden in der Einliegerwohnung einer Villa in Bingen wurden sämtliche Sanierungsarbeiten im und am Haus durchgeführt. Eine fachmänniche Wiederherstellung wurde von uns in mehreren Etappen durch verschiedensten Arbeiten erfolgreich abgeschloßen. Dieses Projekt umfasste wieder mal einige Gewerke, wie z. Bsp.: Wärmedämmung, Verputz-, Estrich-, Trockenbau-, Fliesen- und Malerarbeiten. Einige natürliche Baustoffe wie Lehmputz wurden dabei verwendet und verarbeitet.',
        referencePageRouterName: 'references/brandschaden-bingen',
        interval: 6000,
        images: {
          0: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/brandschaden-bingen/24.jpg',
          },
          1: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/brandschaden-bingen/1.jpg',
          },
        },
      },
      // next
      6: {
        referenceName: 'Brandschaden in Wiesbaden',
        referenceDescription:
          'Sanierungsarbeiten nach Brandschaden: Nachdem das Erdgeschoss eines Einfamilienhauses in Wiesbaden von einem Brand schwerst betroffen wurde, standen hier einige Sanierungsmaßnahmen an. Von Boden- über Wand- bis hin zu Deckenarbeiten war hier alles dabei, was das Handwerkerherz begehrt.',
        referencePageRouterName: 'references/brandschaden-wiesbaden',
        interval: 6000,
        images: {
          0: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/brandschaden-wiesbaden/29.jpg',
          },
          1: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/brandschaden-wiesbaden/1.jpg',
          },
        },
      },
      // next
      7: {
        referenceName: 'Fassade in Mainz-Kostheim',
        referenceDescription:
          'Neue Fassade an einem Mehrfamilienhaus in Mainz-Kostheim: Hier wurde der alte Außenputz an einem Mehrfamilienhaus, sowie der Fliesenbelag an der Treppe zum Keller abgeschlagen. Außer die Strassenseite, wurden alle Seiten des Haupthauses, sowie das komplete Nebengebäude im Hof isoliert und gedämmt. Nachdem der neue Putz auf die Fassade kam, folgten die Maler- und Lackierarbeiten. Der Treppenabgang zum Keller wurde saniert und mit rutschfesten und geeigneten Fliesen verkleidet.',
        referencePageRouterName: 'references/fassade-mainz-kostheim',
        interval: 6000,
        images: {
          0: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/fassade-mainz-kostheim/19.jpg',
          },
          1: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/fassade-mainz-kostheim/2.jpg',
          },
        },
      },
      // next
      8: {
        referenceName: 'Restaurierung Wiesbaden',
        referenceDescription:
          'Restaurierung eines Altbau-Treppenhauses in Wiesbaden: Nach einer Sanierung der Hausinstallation waren im kompletten Treppenhaus einige Renovierungs- und Restaurierungsarbeiten fällig. Abgesehen von bestimmten Baumaterialien, die man berücksichtigen musste, wurden hier sehr feine Maler- und Lackierarbeiten ausgeführt. Einige spezielle Schreinerarbeiten, sowie Sonderanferigungen waren notwendig, um Altes zu bewahren und detailgetreu wieder aufzubauen. (Der Treppen- bzw. Bodenbelag wird noch erneuert!)',
        referencePageRouterName: 'references/restaurierung-wiesbaden',
        interval: 6000,
        images: {
          0: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/restaurierung-wiesbaden/24.jpg',
          },
          1: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/restaurierung-wiesbaden/2.jpg',
          },
        },
      },
      // next
      9: {
        referenceName: 'Sanierung Taunusstein',
        referenceDescription:
          'Sanierung einer Wohnung in Taunusstein: Die 4-Zimmer-Wohnung in Taunusstein wurde saniert und modernisiert. Nachdem die alten Wand-und Bodenbeläge, sowie alle Türen entfernt wurden, folgten die Verputz-, Trockenbau-, Spachtel-, Maler- und Fliesenarbeiten. Der Eichenparkett wurde geklebt, geschliffen, gebürstet, gebeizt und geölt.Mit dem Einbau der Zimmer- und Schiebetüren war es vollendet.',
        referencePageRouterName: 'references/sanierung-taunusstein',
        interval: 6000,
        images: {
          0: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/sanierung-taunusstein/19.jpg',
          },
          1: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/sanierung-taunusstein/1.jpg',
          },
        },
      },
      // next
      10: {
        referenceName: 'Wasserschaden Mainz-kastel',
        referenceDescription:
          'Wasserschaden in Mainz-Kastel: Nach dem Wasserschaden in einem Reiheneckhaus in Mainz-Kastel musste nach Trocknungsmaßnahmen die Wiederherstellung fortgesetzt werden. Hauptsächlich waren Verputz-, Maler- und Fliesenarbeiten notwendig. Im Zuge der Sanierung wurde das Metall-Treppengeländer angeschliffen und lackiert.',
        referencePageRouterName: 'references/wasserschaden-mainz-kastel',
        interval: 6000,
        images: {
          0: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/wasserschaden-mainz-kastel/11.jpg',
          },
          1: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/wasserschaden-mainz-kastel/1.jpg',
          },
        },
      },
      // next
      11: {
        referenceName: 'Bad Fußbodenerneuerung nähe Heilbronn',
        referenceDescription:
          'Im Badezimmer eines Einfamilienhauses in der Nähe von Heilbronn wurde der Bodenaufbau erneuert, der Duschbereich umgebaut, eine elektrische Fußbodenheizung eingebaut und Großformatfliesen verlegt.',
        referencePageRouterName: 'references/bad-heilbronn',
        interval: 6000,
        images: {
          0: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/bad-heilbronn/5.jpg',
          },
          1: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/bad-heilbronn/3.jpg',
          },
        },
      },
      // next
      12: {
        referenceName: 'Balkon Mannheim',
        referenceDescription:
          'Nach dem Wanddurchbruch und dem Türeinbau folgte der Bau und die Montage eines Holzbalkones, inkl. Edelstahlgeländers.',
        referencePageRouterName: 'references/balkon-mannheim',
        interval: 6000,
        images: {
          0: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references/balkon-mannheim/2.jpg',
          },
          1: {
            imageName: '',
            imageDescription: '',
            imageSrc: 'https://medo-sanierung.de/public_files/img/references//balkon-mannheim/1.jpg',
          },
        },
      },
    },
  }),
};
</script>
